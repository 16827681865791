import React from 'react';
import Banner from './Banner';
import About from './About';
import Services from './Services';
import Feature from './Feature';
import Goals from './Goals';
import Testimonial from './Testimonial';
import TopProd from './TopProd';

function Home() {
  return (
    <div >
      <Banner/>
      <About/>
      <Services/>
      <Feature/>
      <TopProd/>
      <Goals/>   
      {/* <Testimonial/> */}
    </div>
  )
}

export default Home
