import React, { useEffect } from "react";
import img from "../../images/Image.png";
import img2 from "../../images/24 Hours.png";
import img3 from "../../images/Customize.png";
import img4 from "../../images/Training.png";
import img5 from "../../images/Threat.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

function Feature() {
  useEffect (() => {
    AOS.init({ duration: 1000 });
  }, []);
  const navigate = useNavigate();
  return (
    <div className="feature-point w-full grid pb-5  grid-cols-2 max-[600px]:flex gap-10 pt-20 justify-center
    px-20 max-[850px]:px-6">
      <div data-aos='zoom-in' className="feature-img w-full h-full max-[600px]:hidden">
      <img  src={img} alt="feature-img"/>
      </div>
      <div className="w-full h-full text-white flex flex-col gap-3">
        <p className="text-lg text-cyan-400 font-medium">FEATURE POINT</p>
        <h1 className='text-5xl font-medium max-[800px]:text-2xl'>
          Key Service Features Protecting You
        </h1>
        <p className='text-md max-[850px]:text-xs'>
        "Experience unparalleled protection with our cutting-edge security features. From advanced threat detection to secure data encryption, our comprehensive suite of services delivers the robust security your business demands in today's digital world."

        </p>
        <button onClick={() => navigate('/productpage')} className='bg-cyan-700 text-white w-52 mt-5 text-sm py-3 rounded-md max-[800px]:text-xs max-[800px]:py-2 max-[800px]:w-40'>
          Read More
        </button>
        <div className="w-full grid gap-6 grid-cols-2 mt-3">
          <div  data-aos='zoom-in'  className="bg-neutral-800  bg-opacity-75 flex flex-col gap-3 py-4  px-4 rounded-xl  text-white 5 w-full">
            <img src={img3} alt="icon" className="w-10" />
            <span className="font-medium text-xl max-[850px]:text-sm">
              Customized Security Solutions
            </span>
            <p className="text-sm max-[850px]:text-xs ">
            Tailored security strategies to match your unique business needs and risk profile.
            </p>
          </div>
          <div  data-aos='zoom-in'  className="bg-neutral-800 bg-opacity-75 flex flex-col gap-3 py-4  px-4 rounded-xl  text-white 5 w-full">
            <img src={img5} alt="icon" className="w-10" />
            <span className="font-medium text-xlmax-[850px]:text-sm ">
              Vulnerability Assessment
            </span>
            <p className="text-sm max-[850px]:text-xs">
            In-depth scanning and testing to find security gaps before attackers do.
            </p>
          </div>
          <div  data-aos='zoom-in'  className="bg-neutral-800 bg-opacity-75 flex flex-col gap-3 py-4  px-4 rounded-xl  text-white 5 w-full">
            <img src={img2} alt="icon" className="w-10" />
            <span className="font-medium text-xl max-[850px]:text-sm">24/7 Incident Response</span>
            <p className="text-sm max-[850px]:text-xs">
            Round-the-clock security monitoring and immediate threat resolution.
            </p>
          </div>
          <div  data-aos='zoom-in'  className="bg-neutral-800 bg-opacity-75 flex flex-col gap-3 py-4  px-4 rounded-xl  text-white 5 w-full">
            <img src={img4} alt="icon" className="w-10" />
            <span className="font-medium text-xl max-[850px]:text-sm">User Training Programs</span>
            <p className="text-sm max-[850px]:text-xs">
            Empower your team with essential cybersecurity knowledge and best practices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
