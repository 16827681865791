 
 import React from 'react'
 import img1 from '../../images/products/blog-two-4.jpg'
 import img2 from '../../images/products/download.jpg'
 import img3 from '../../images/products/blog-two-3.jpg'
 import { IoGlobeOutline } from "react-icons/io5";
 import { TbPhoneCall } from "react-icons/tb";
 import { FiMail } from "react-icons/fi";

 
 function Contact() {
   return (
     <div className='blog bg-black grid grid-cols-3 gap-10 py-32 px-8 max-[970px]:grid-cols-2 max-[650px]:grid-cols-1'>
        <div className='w-full rounded-xl  bg-blue-800 bg-opacity-15'>
            <img src={img1} alt="" className='rounded-xl w-full h-3/5' /> 
            <div className='w-full p-5 flex flex-col gap-4'>
                <p className='font-medium text-cyan-400 text-xl'>Location Here</p>
                <div className='flex items-start gap-3'>
                    <IoGlobeOutline className='text-red-700 text-3xl'/>
                    <p className='text-white text-sm font-medium'>E-29, Third Floor, E Block, Sector 63, Noida, Uttar Pradesh 201301</p>
                </div>
            </div>           
        </div>       
        <div className='w-full rounded-xl  bg-blue-800 bg-opacity-15'>
            <img src={img2} alt="" className='rounded-xl w-full h-3/5' /> 
            <div className='w-full p-5 flex flex-col gap-4'>
                <p className='font-medium text-cyan-400 text-xl'>Call Here</p>
                <div className='flex items-center gap-3'>
                    <TbPhoneCall className='text-red-700 text-3xl'/>
                    <p className='text-white text-sm font-medium'>
                    +91-8178803636</p>
                </div>
            </div>           
        </div>       
        <div className='w-full rounded-xl  bg-blue-800 bg-opacity-15'>
            <img src={img3} alt="" className='rounded-xl w-full h-3/5' /> 
            <div className='w-full p-5 flex flex-col gap-4'>
                <p className='font-medium text-cyan-400 text-xl'>Email Here</p>
                <div className='flex items-center gap-3'>
                    <FiMail className='text-red-700 text-3xl'/>
                    <p className='text-white text-sm font-medium'>
                    fly@maplecloudtechnologies.com</p>
                </div>
            </div>           
        </div>       
     </div>
   )
 }
 
 export default Contact
 