 
  
 import React from "react";
 import img from "../../images/service/service_banner.jpg";
 import img1 from "../../images/products/docker.png";
 import img2 from "../../images/products/vul_dashboard.png";
 import { IoPricetagsOutline } from "react-icons/io5";
 
 
 function Network() {
   return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
        <img src={img} alt="img" className="w-full h-[380px] rounded-xl" />
        <h1 className="text-white text-3xl font-medium">Introduction</h1>
        <p>
        At MCT, we specialize in constructing flexible and scalable network infrastructures. As an experienced partner, we understand the importance of designing robust and easy-to-manage infrastructures. Our solutions focus on network strategy, optimization, integration, and security, helping our clients identify and eliminate risks, analyze network approaches, and adopt services that optimize their networks. Our approach, known as the "Wheel of Life," includes four key phases: Assessment, Design, Implementation, and Management. This methodology ensures innovative and efficient network solutions, whether dealing with Software Defined Technologies, Automation, or Analytics.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Features
        </h1>
        <li>Enhanced Communication : Our enterprise networks provide a platform for seamless communication among employees, departments, and locations. With unified communication tools such as email, instant messaging, video conferencing, and VoIP, we enable real-time communication and collaboration, overcoming geographical boundaries.</li>
        <li>Improved Collaboration : Facilitate collaboration by enabling employees to share documents, files, and resources easily. Our collaborative tools, including document management systems, project management software, and shared calendars, streamline teamwork and enhance productivity by centralizing information and fostering collaboration across teams.</li>
        <h1 className="text-white text-3xl font-medium">
        Our Approach
        </h1>
        <li>Assessment : Evaluate current network infrastructure to identify strengths and areas for improvement.</li>
        <li>Design : Create a tailored network design that meets your specific needs and goals.</li>
        <li>Assessment : Deploy the designed network solutions effectively and efficiently.</li>
        <li>
        Assessment : Provide ongoing management and support to maintain optimal network performance.</li>
        <h1 className="text-white text-3xl font-medium">
        Get Started
        </h1>
        <p>
        Transform your network infrastructure with MCT. Contact us today to discover how our expertise can help you build a robust, flexible, and secure network environment that drives your business forward.
        </p>
      </div>

      <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
          <p className="text-white font-medium text-xl ">
          MCT Enterprice Network
          </p>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Centralized Data Storage and Access:
            </h1>
            <br />
            <li className="text-white">
            Enterprise networks allow organizations to centralize data storage, making it easier for employees to access and share information securely. Network-attached storage (NAS) and file servers provide centralized repositories for storing documents, databases, and multimedia files, ensuring data consistency, integrity, and availability..
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Scalability and Flexibility
            </h1>
            <br />
            <li className="text-white">
            Enterprise networks are designed to scale with the growing needs of organizations, allowing for seamless expansion and adaptation to changing requirements. Virtualization technologies, cloud integration, and software-defined networking (SDN) enable organizations to scale their network infrastructure dynamically and allocate resources as needed.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Enhanced Security
            </h1>
            <br />
            <li className="text-white">
            Enterprise networks implement robust security measures to protect sensitive data and resources from unauthorized access, cyber threats, and data breaches. This includes network segmentation, firewalls, intrusion detection/prevention systems (IDS/IPS), encryption, authentication mechanisms, and security policies to safeguard against internal and external threats.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Remote Access and Mobility
            </h1>
            <br />
            <li className="text-white">
            Enterprise networks enable employees to access corporate resources securely from remote locations, enabling flexible work arrangements and remote collaboration. Virtual private networks (VPNs), remote desktop services, and mobile device management (MDM) solutions provide secure remote access to network resources while maintaining data confidentiality and compliance.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Streamlined Operations and Management
            </h1>
            <br />
            <li className="text-white">
            Enterprise networks centralize network management and administration, allowing IT teams to monitor, configure, and troubleshoot network devices and services efficiently. Network management tools, such as network monitoring software, configuration management systems, and automation scripts, streamline network operations and reduce downtime.
            </li>
          </div>
        </div>
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
          <p className="text-white font-medium text-2xl ">
          Key Benefits of MCT Enterprice Network
          </p>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Security Enhancement</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Compliance Adherence</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Operational Efficiency</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Cost Savings</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">User Productivity</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Single Sign-On (SSO)</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Reduced IT Workload</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">
            Risk Mitigation
            </p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Password Managemen </p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Scalability</p>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
 }
 
 export default Network
 