import React, { useEffect } from "react";
import { TiStarFullOutline } from "react-icons/ti";
import AOS from "aos";
import "aos/dist/aos.css";
import img1 from "../../images/client_imgs/image 38.png";
import img2 from "../../images/client_imgs/image 39.png";
import img3 from "../../images/client_imgs/image 41.png";
import img4 from "../../images/client_imgs/image 42.png";

function Testimonial() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="home-testimonial pb-5 w-full px-20 max-[940px]:px-5  max-[730px]:px-6  py-4 flex flex-col gap-10 items-center">
      <div className="w-3/5 max-[730px]:w-full flex items-center gap-3 flex-col">
        <p className="text-lg text-cyan-400 font-medium ">TESTIMONIAL</p>
        <h1 className="text-white font-medium text-5xl text-center max-[730px]:text-3xl">
          See What Our Clients <br />
          Are Saying
        </h1><p className="text-center text-white">Each testimonial highlights different service aspects while maintaining authenticity. Would you like any adjustments?
        </p>
      </div>
      <div className="w-full grid grid-cols-2 max-[690px]:grid-cols-1 gap-4">
        <div data-aos="zoom-in" className="w-full ">
          <div className="bg-neutral-800 bg-opacity-25 h-40 hover:border-2 cursor-pointer hover:border-cyan-500 bg-opacity-3 flex flex-col  gap-3 p-5 rounded-xl  text-white 5 w-full">
            <p className=" text-gray-300 text-xs">
            Their cybersecurity solutions transformed our digital infrastructure. The comprehensive assessment and rapid response capabilities have given us complete confidence in our security posture. Outstanding service delivery at every level.

            </p>
            <div className="w-full flex items-center  gap-3">
              <img src={img1} alt="img" className="w-10 h-10 rounded-full" />
              <div className="text-white flex flex-col ">
                <h1 className="text-md font-medium">STERLITE
                </h1>
                <span className="text-xs text-gray-300">
                Investor Metric Executive
                </span>
                <div className="flex gap-1 mt-1 text-sm ">
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in" className="w-full  ">
          <div className="bg-neutral-800 bg-opacity-25 h-40 hover:border-2 cursor-pointer hover:border-cyan-500 bg-opacity-3 flex flex-col  gap-3 p-5 rounded-xl  text-white 5 w-full">
            <p className=" text-gray-300 text-xs">
            Exceptional security training programs that made complex concepts accessible to our entire team. The customized security solutions perfectly addressed our unique challenges. Five-star service from start to finish.
            </p>
            <div className="w-full flex items-center  gap-3">
              <img src={img2} alt="img" className="w-10 h-10 rounded-full" />
              <div className="text-white flex flex-col ">
                <h1 className="text-md font-medium">FUTURE GENERALLY </h1>
                <span className="text-xs text-gray-300">
                Human Integration Agent
                </span>
                <div className="flex gap-1 mt-1 text-sm ">
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in" className="w-full ">
          <div className="bg-neutral-800 bg-opacity-25 h-40 hover:border-2 cursor-pointer hover:border-cyan-500 bg-opacity-3 flex flex-col  gap-3 p-5 rounded-xl  text-white 5 w-full">
            <p className=" text-gray-300 text-xs">
            The 24/7 incident response team has proven invaluable. Their proactive approach to vulnerability assessment helped us identify and address potential threats before they became issues. Truly professional service.
            </p>
            <div className="w-full flex items-center  gap-3">
              <img src={img4} alt="img" className="w-10 h-10 rounded-full" />
              <div className="text-white flex flex-col ">
                <h1 className="text-md font-medium">POWERGRID</h1>
                <span className="text-xs text-gray-300">
                  Regional Branding Consultant
                </span>
                <div className="flex gap-1 mt-1 text-sm ">
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in" className="w-full ">
          <div className="bg-neutral-800 bg-opacity-25 h-40 hover:border-2 cursor-pointer hover:border-cyan-500 bg-opacity-3 flex flex-col  gap-3 p-5 rounded-xl  text-white 5 w-full">
            <p className=" text-gray-300 text-xs">
            We've seen dramatic improvements in our security framework since implementing their solutions. The team's expertise and dedication to customer service is remarkable. Would highly recommend any organization taking security seriously.
            </p>
            <div className="w-full flex items-center  gap-3">
              <img src={img3} alt="img" className="w-10 h-10 rounded-full" />
              <div className="text-white flex flex-col ">
                <h1 className="text-md font-medium">MARIANNE BODE</h1>
                <span className="text-xs text-gray-300">
                Product Intranet Agent
                </span>
                <div className="flex gap-1 mt-1 text-sm ">
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                  <TiStarFullOutline className="text-yellow-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
