 
 import React from "react";
 import cloud from "../../images/blog/cloudblog.png";
 import img1 from "../../images/products/docker.png";
 import img2 from "../../images/products/vul_dashboard.png";
 import { IoPricetagsOutline } from "react-icons/io5";
 
 function Cloud() {
   return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={cloud} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
          At MCT, we provide comprehensive cloud services to individuals and organizations through cloud-based computing servers. Unlike traditional on-premise
          </p>
          <h1 className="text-white text-3xl font-medium">
          What is Cloud Computing?
          </h1>
          <p>
          If you're new to the concept, let us demystify it for you. Cloud computing involves using hardware and software resources to deliver services over the Internet. In simpler terms, it's a type of Internet-based computing where various services—such as servers, memory, storage, and applications—are provided to your devices through the Internet.
          </p>
          <h1 className="text-white text-3xl font-medium">
          Why Choose Cloud Services?
          </h1>
          <p>
          Selecting a cloud-based environment can be as complex as your business. Our cloud experts are here to guide you through the decision-making process, focusing on key aspects such as:
          <li>Scalability : Easily scale your resources up or down based on your needs.</li>
          <li>Storage Options : Choose from a variety of storage solutions to suit your requirements.
          </li>
          <li>Choice Control : Maintain control over your cloud environment with customizable options.
          </li>
          <li>Security : Ensure your data is protected with robust security measures.
          </li>
          <li>Tools Flexibility : Utilize a range of tools and services to enhance your operations.
          </li>
          <li>Accessibility : Access your data and applications from anywhere, at any time.
          </li>
          <li>Time to Market (T2M) : Accelerate your time to market with efficient cloud solutions.
          </li>
          <li>Optimized Cost : Pay only for the resources you use, optimizing your costs.
          </li>
          <li>Competitive Advantage : Stay ahead with the latest cloud technologies.
          </li>
          <li>Alliance and Restructured Action : Benefit from strategic partnerships and streamlined processes.
          </li>
          </p>
          <h1 className="text-white text-3xl font-medium">
          Our Services
          </h1>
          <p>
          Whether you are an IT team looking to outsource cloud application management, a start-up, or an enterprise with a global presence, our expert team is here to help. We bridge the gap and simplify the complexities of cloud computing, ensuring you implement the best solution for your business.
          </p>
          <h1 className="text-white text-3xl font-medium">
          Get Started
          </h1>
          <p>
          Transform your business with our tailored cloud services. Let MCT guide you through the cloud computing landscape, providing the expertise and support you need to succeed. Contact us today to learn more about how we can help your organization thrive in the digital age.
          </p>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
            MCT Cloud            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              High Availability
              </h1>
              <br />
              <li className="text-white">
              Built-in redundancy and failover mechanisms at the network level to ensure continuous availability of applications and services, even in the event of hardware failures or network disruptions.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Load Balancing
              </h1>
              <br />
              <li className="text-white">
              Automatic distribution of incoming network traffic across multiple servers or instances to optimize resource utilization, improve fault tolerance, and enhance application scalability.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Content Delivery Network (CDN)
              </h1>
              <br />
              <li className="text-white">
              Integration with CDN services to cache and deliver content from edge locations closer to end-users, reducing latency and improving user experience for globally distributed applications.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Software-Defined Networking (SDN):
              </h1>
              <br />
              <li className="text-white">
              Abstracting network infrastructure into software overlays to enable programmable, dynamic, and centralized control of network resources, facilitating automation, agility, and optimization.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Cost Management
              </h1>
              <br />
              <li className="text-white">
              Visibility into network usage and costs, with cost allocation and optimization tools to help organizations manage their cloud networking expenses efficiently.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
            Key Benefits of MCT Cloud
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Security Enhancement</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Compliance Adherence</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Operational Efficiency</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Cost Savings</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">User Productivity</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Single Sign-On (SSO)</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Reduced IT Workload</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
              Risk Mitigation
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Password Managemen</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Scalability </p>
            </div>
          </div>
        </div>
      </div>
    </div>
   )
 }
 
 export default Cloud
 