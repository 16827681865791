import React from "react";
import { IoPricetagsOutline } from "react-icons/io5";

function MapleDoc() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img
            src="https://img.freepik.com/free-photo/files-index-content-details-document-archives-concept_53876-133676.jpg?w=900&t=st=1707124449~exp=1707125049~hmac=d58d249f2a581cea4299ba3dceeceb9cf3ad16af3eaca3712b5e8f7be237b603"
            alt="img"
            className="w-full h-[380px] rounded-xl"
          />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            MapleCloud Technologies offers a comprehensive Database Management
            System (DBMS) that empowers users to efficiently manage, store,
            retrieve, and manipulate data. Our DBMS software provides a
            user-friendly interface for seamless interaction with databases,
            enabling tasks such as data creation, modification, and deletion, as
            well as defining data structures and relationships. At MapleCloud
            Technologies, our DBMS ensures the smooth organization and storage
            of data while prioritizing data integrity and security. Our system
            incorporates advanced features such as indexing, transaction
            management, and reliable backup and recovery mechanisms. This
            guarantees optimal performance and minimizes the risk of data loss.
            With DBMS, handling vast amounts of data becomes effortless. Our
            solution streamlines data storage, access, and analysis, empowering
            organizations to make informed decisions swiftly and effectively.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Features of MapleDoc
          </h1>
          <p>
            MapleCloud Technologies offers a comprehensive Database Management
            System (DBMS) that empowers users to efficiently manage, store,
            retrieve, and manipulate data. Our DBMS software provides a
            user-friendly interface for seamless interaction with databases,
            enabling tasks such as data creation, modification, and deletion, as
            well as defining data structures and relationships.
            <br />
            <br />
            At MapleCloud Technologies, our DBMS ensures smooth organization and
            storage of data while prioritizing data integrity and security. Our
            system incorporates advanced features such as indexing, transaction
            management, and reliable backup and recovery mechanisms,
            guaranteeing optimal performance and minimizing the risk of data
            loss. With our DBMS, handling vast amounts of data becomes
            effortless. Our solution streamlines data storage, access, and
            analysis, empowering organizations to make informed decisions
            swiftly and effectively.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Features of MapleDoc
          </h1>
          <p>
            MapleCloud Technologies DBMS stands out from the competition due to
            its unique features:
          </p>
          <li>
            Advanced AI-Powered Search : Incorporates cutting-edge AI technology
            for smart and context-aware document searches, making it easier to
            find relevant information.
          </li>
          <li>
            Intuitive Mobile App : Offers a user-friendly mobile app, enabling
            access to documents on-the-go, promoting flexibility, and enhancing
            productivity.
          </li>
          <li>
            Customizable Workflows : Designed to scale effortlessly as the
            organization grows, accommodating increased document volumes and
            user demands.
          </li>
          <h1 className="text-white text-3xl font-medium">
            Best Practices for Implementing MapleDoc
          </h1>
          <p>
            To maximize the benefits of MapleCloud Technologies DBMS, businesses
            should follow these best practices during the implementation
            process:
          </p>
          <li>
            Needs Assessment:Conduct a comprehensive assessment of the
            organization's document management requirements and workflows to
            ensure that MapleCloud Technologies DBMS aligns with specific needs.
          </li>
          <li>
            Thorough User Training :Provide comprehensive training to all users,
            including administrators and employees, to promote seamless adoption
            and optimal utilization of the DBMS.
          </li>
          <li>
            Data Migration Strategy: Develop a structured data migration plan to
            transfer existing documents and information to MapleCloud
            Technologies DBMS accurately and efficiently.
          </li>
          <li>
            Security Implementation: Implement robust security measures to
            protect sensitive data, including data encryption, user access
            controls, and regular security audits.
          </li>
          <li>
            Continuous Support and UpdateEnsure ongoing support and regular
            updates from MapleCloud Technologies to keep the DBMS up-to-date and
            functioning optimally.
          </li>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
              Key feature of MapleDoc
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Document Capture
              </h1>
              <br />
              <li className="text-white">
                MapleDoc allows seamless document capture through various
                channels, including scanning, electronic forms, email
                integration, and direct uploads.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Centralized Storage and Organization
              </h1>
              <br />
              <li className="text-white">
                All documents are securely stored in a centralized repository,
                organized into customizable folders and subfolders. The system
                employs metadata tagging for effortless categorization and quick
                retrieval.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Version Control
              </h1>
              <br />
              <li className="text-white">
                MapleCloud Technologies DMS maintains a comprehensive version
                history of documents, enabling users to track changes, view
                revisions, and revert to previous versions if needed.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Security and Access Control
              </h1>
              <br />
              <li className="text-white">
                Security is a top priority for MapleDoc. It employs robust
                access control measures, encryption protocols, and audit trails
                to ensure documents are accessible only to authorized personnel.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Intelligent Search and Retrieval
              </h1>
              <br />
              <li className="text-white">
                The MapleDoc features an intelligent search function, allowing
                users to quickly locate documents using keywords, metadata, or
                content-based searches, significantly reducing time spent on
                document retrieval.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
              Key Benefits of MapleDoc
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Enhanced Productivity</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Cost Savings</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
                Heightened Security and Compliance
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Disaster Recovery</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Eco-Friendly Initiatives</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Needs Assessment</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Thorough User Training</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Data Migration Strategy</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Security Implementation</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
                Continuous Support and Updates
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapleDoc;
