 
 import React from "react";
 import iam from "../../images/products/iam.png";
 import { IoPricetagsOutline } from "react-icons/io5";
 
 function MCTAuth() {
   return (
    <div className="blog w-full ">
    {/* <div className="w-full h-screen ">
    <img src={cloudbanner} alt="img" className="w-full h-[95%]" />
    <div className=" w-full absolute flex flex-col my-5 gap-4 px-10 bottom-10 ">
      <span className="text-yellow-400 text-lg font-medium">TECH</span>
      <p className="text-white text-3xl font-semibold pb-8 border-b-2 border-white">
        Cloud Security 101: Protecting Your Data in <br /> the Cloud
      </p>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center gap-4">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShwIjMMGGKfEHrawR7b3xPPj1mCVe0UFwyjQ&s"
            alt="img"
            className="w-10 h-10 rounded-full"
          />
          <div className="flex flex-col gap-1 my-3">
            <p className="text-sm flex gap-2 items-center">
              <img src={vec} className="w-3 h-3 " />
              <span className="text-md text-white font-medium">
                Luisa Mendas
              </span>
            </p>
            <p className="text-white text-xs">Feb 12,2023 - 3 min read</p>
          </div>
        </div>
        <div className="flex items-center gap-3 text-white text-2xl">
          <IoLogoWhatsapp />
          <IoLogoInstagram />
          <FaXTwitter />
        </div>
      </div>
    </div>
  </div> */}
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
        <img src={iam} alt="img" className="w-full h-[380px] rounded-xl" />
        <h1 className="text-white text-3xl font-medium">Introduction</h1>
        <p>
          MCT Authenticator is a tool that provides authentication and
          authorization services for applications and services. It is designed
          to help developers easily implement security features in their
          applications without having to write complex code. MCT Authenticator
          offers features like Single Sign-On (SSO), Social Login, User
          Federation, Two-Factor Authentication (2FA), Multi-Factor
          Authentication (MFA), Authorization and Permissions, and other
          security features to protect applications and user data. Built on
          open standards like SAML 2.0, OAuth 2.0, and OpenID Connect, MCT
          Authenticator integrates seamlessly with various application
          platforms and frameworks. It is versatile, catering to web and
          mobile apps, IoT devices, and microservices. With MCT Authenticator,
          developers can focus on building applications while delegating
          security to a centralized identity provider. MCT Authenticator is
          highly scalable and can be deployed on-premises or in the cloud. It
          also includes an administration console for managing users, roles,
          and permissions for applications.
        </p>
        <h1 className="text-white text-3xl font-medium">Features</h1>
        <p>
        MCT Authenticator is an Identity and Access Management (IAM) solution offering various features and benefits for organizations:
        </p>

        <li>
          Authentication and Authorization: Provides a unified framework for
          web applications, APIs, and microservices. Supports authentication
          protocols such as OpenID Connect, OAuth 2.0, and SAML 2.0. Enables
          role-based access control (RBAC) and fine-grained authorization
          policies.
        </li>
        <li>
          Single Sign-On (SSO): Allows users to authenticate once and access
          multiple applications without needing to log in again. This improves
          user experience and security by reducing the number of passwords
          users need to remember.
        </li>
        <li>
          Two-Factor Authentication (2FA) and Multi-Factor Authentication
          (MFA): Adds an extra layer of security by requiring additional
          verification such as SMS codes, email codes, or biometric factors.
        </li>
        <li>
          User Management: Offers a web-based console for managing users,
          roles, and groups. Supports LDAP and Active Directory integration,
          allowing organizations to synchronize user data from external
          sources.
        </li>
        <p>
          In summary, MCT Authenticator provides a comprehensive IAM solution
          for web applications, APIs, and microservices. Its robust features
          and benefits make it a popular choice among developers and
          organizations seeking a customizable and cost-effective IAM
          solution.
        </p>

        <h1 className="text-white text-3xl font-medium">
          MCT Authenticator Architecture Overview
        </h1>
        <li>
          MCT Authenticator Adapters: Offers a web-based console for managing
          users, roles, and groups. Supports LDAP and Active Directory
          integration, allowing organizations to synchronize user data from
          external sources.
        </li>
        <li>
          MCT Authenticator APIs: RESTful endpoints that allow third-party
          applications to integrate with MCT Authenticator programmatically.
          The APIs are flexible, enabling developers to create, update, and
          delete users, roles, clients, and other resources.
        </li>

      </div>

      <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
          <p className="text-white font-medium text-xl ">
          MCT Authenticator Features
          </p>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            User Authentication

            </h1>
            <br />
            <li className="text-white">
            (MFA): Requires users to verify their identity using multiple authentication factors, such as passwords, biometrics, or one-time codes.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            User Authorization
            </h1>
            <br />
            <li className="text-white">
            (RBAC): Assigns specific roles to users based on their responsibilities, granting them appropriate access privileges.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            User Provisioning and Deprovisioning
            </h1>
            <br />
            <li className="text-white">
            Automated Onboarding: Streamlines the process of adding new users by automating account creation and access provisioning.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Single Sign-On (SSO)
            </h1>
            <br />
            <li className="text-white">
            Centralized Authentication: Enables users to log in once and access multiple systems or applications without re-entering credentials. Federated Identity: Integrates with external identity providers to verify user identities
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Audit and Logging
            </h1>
            <br />
            <li className="text-white">
            Activity Monitoring: Logs and monitors user activities to detect and respond to suspicious behavior. Audit Trails: Maintains detailed records of user access, modifications, and system events for compliance and security analysis.
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
 }
 
 export default MCTAuth
 