 
 import React from "react";
 import sdwan from "../../images/products/agent-net.png";
 
 function Sdwan() {
   return (
    <div className="blog w-full ">
    <div className="flex pt-20  w-full justify-center">
      <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
        <img src={sdwan} alt="img" className="w-full h-[400px] rounded-xl" />
        <h1 className="text-white text-3xl font-medium">MapleCloud AgentNet (SDWAN) Solution</h1>
        <p>
        MapleCloud AgentNet (SDWAN) is a cutting-edge networking technology designed to optimize and streamline your organization's connectivity. By leveraging software-defined networking principles, MapleCloud AgentNet (SDWAN) offers unparalleled flexibility, security, and performance, empowering businesses to meet the demands of today's dynamic digital landscape.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Key Features
        </h1>
        <li>
         
Intelligent Routing: MapleCloud AgentNet (SDWAN) intelligently routes traffic across multiple network paths, dynamically selecting the most efficient route based on application requirements and network conditions. This ensures optimal performance and reliability for critical applications.
        </li>
        <li>
          
Centralized Management: With a centralized management interface, MapleCloud AgentNet (SDWAN) simplifies network administration and monitoring. Administrators can easily configure policies, prioritize traffic, and monitor performance from a single dashboard, streamlining operations and reducing complexity.

        </li>
        <li>
          Automated Configuration Backups: Never lose critical configuration
          settings again. With our solution, you can automate configuration
          backups for all your network devices, ensuring quick recovery and
          minimal downtime in the event of a failure or security breach.
        </li>
        <li>
        Secure Connectivity: Security is paramount in today's digital environment. MapleCloud AgentNet (SDWAN) employs advanced encryption protocols and security measures to safeguard data in transit, protecting against threats such as malware, ransomware, and unauthorized access. Additionally, built-in firewall capabilities provide an added layer of defense at the network perimeter.
        </li>
      </div>
    </div>
  </div>
   )
 }
 
 export default Sdwan
 