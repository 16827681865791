import React from "react";
import siem from "../../images/products/SIEM.png";
import { IoPricetagsOutline } from "react-icons/io5";

function Siem() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={siem} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            The EventShield tool is essential for organizations to monitor and
            protect their assets and data against cyber threats. EventShield
            functions by collecting security-related data from various sources,
            such as logs, network traffic, and other security tools. This data
            is then analyzed using advanced analytics to detect suspicious
            activities, potential security breaches, and other security-related
            events in real-time. EventShield consists of an endpoint security
            agent, deployed on monitored systems, and a management server that
            collects and analyzes data gathered by the agents. It provides a
            data visualization dashboard that allows users to navigate through
            their security alerts. Additionally, EventShield offers agentless
            monitoring of endpoints using the syslog protocol. It can present
            security events both graphically and in log format, using
            pre-defined and custom rules based on CVEs and threats. EventShield
            matches rulesets within its daemon and outputs logs according to the
            decoders written for the matching ruleset.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Agentless Monitoring
          </h1>
          <p>
            EventShield monitors headless devices (where agents can’t be
            installed) through syslogs on TCP & UDP. It has a robust mechanism
            for detecting behaviors, anomalies, and traffic movement within and
            outside network devices.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Agent-Based Monitoring
          </h1>
          <p>
            Servers, VMs, and operating systems can be monitored using
            EventShield's agent-based solution. It provides secure tunnel-based
            agent integration on different OS platforms, including Windows,
            Linux, and macOS. This solution ensures comprehensive monitoring and
            protection across various environments.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Why Choose EventShield?
          </h1>
          <p>
            SIEM (Security Information and Event Management) is crucial for
            organizations due to the growing complexity and sophistication of
            cyber threats. It provides a centralized platform for collecting,
            analyzing, and correlating security event data from various sources.
            SIEM helps organizations detect and respond to security incidents
            promptly, improving threat visibility and incident response
            capabilities. By investing in the EventShield solution,
            organizations benefit from advanced threat detection, real-time
            monitoring, log management, compliance reporting, and streamlined
            incident response. This ensures that their critical assets are
            effectively and efficiently protected from evolving cyber threats.
          </p>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
              EventShield Features
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Log Data Analysis
              </h1>
              <br />
              <li className="text-white">
                EventShield assists users by automating log management and
                analysis to accelerate threat detection.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                File Integrity Monitoring
              </h1>
              <br />
              <li className="text-white">
                It generates an alert when it detects a change in the file
                system, simplifies management of compliance regulations with.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Rootkits Detection
              </h1>
              <br />
              <li className="text-white">
                EventShield periodically scan its endpoints connected to agents
                for any kind of anomaly and rootkits detection.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Vulnerability Detection & Container Security
              </h1>
              <br />
              <li className="text-white">
                EventShield manages the system inventory of the endpoints along
                with the processes and network ports open in the system.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
              Agent-Based Monitoring
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Enhanced Threat Protection</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Log Management</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Incident Response</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Real-Time Alerts</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Threat Intelligence</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Compliance Reporting</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Scalability</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
                Customizable Rules and Policies
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Centralized Visibility</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Data Visualization </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Siem;
