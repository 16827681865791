import React from "react";
import nms from "../../images/products/mct-nms.jpg";
import { IoPricetagsOutline } from "react-icons/io5";

function Nms() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={nms} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            MCT NMS is a network management platform designed to efficiently
            monitor and manage complex networks. It has evolved into a mature
            and reliable solution for network monitoring and management,
            providing end-to-end monitoring and management of network devices,
            servers, applications, and services. MCT NMS collects and reports on
            a variety of data from a computer network, including routers,
            switches, firewalls, load balancers, and even endpoints like servers
            and workstations. The collected data is filtered and analyzed to
            identify various network problems, such as device failures, link
            outages, interface errors, packet loss, application response time
            issues, and configuration changes. The functions of a network
            monitoring and management system can be broken down into several
            categories, each performing a specific function.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Features of MCT NMS
          </h1>
          <li>
            Event Collection and Processing : Utilizes Simple Network Management
            Protocol (SNMP) traps and syslog to collect network event data.
            Event processing identifies critical events, reducing the volume of
            alerts network administrators must handle to identify the root cause
            of problems.
          </li>
          <li>
            Network Change and Configuration Management (NCCM) : Archives
            network device configurations and automates configuration updates.
            Configurations can be retrieved and updated using the command-line
            interface (CLI). Configuration analysis identifies day-to-day
            changes and audit compliance exceptions where configurations don't
            match network design policies.
          </li>
          <li>
            Telemetry : Employs network telemetry to push network performance
            data to the monitoring system. Uses representational state transfer
            (REST) interfaces to collect data in consistent formats.
          </li>
          <li>
            IP Address Management : Tracks IP address usage and controls the
            allocation of addresses to network devices.
          </li>
          <li>
            Topology Mapping : Collects device connection data to create
            physical and logical topology maps, which form the foundation of
            basic troubleshooting. SNMP polling is used to collect data on
            routing neighbors (Layer 3), switching neighbors (Layer 2), address
            translation tables (Layer 2 to Layer 3 mapping), and neighbor
            discovery protocols like Link Layer Discovery Protocol (LLDP).
          </li>
          <li>
            Digital Experience Monitoring : Employs active testing tools, such
            as ping, traceroute, and synthetic monitoring, to ensure the network
            is functioning as intended. Combines application performance
            monitoring with network monitoring to diagnose whether an
            application problem is due to the network or another factor,
            including external networks.
          </li>
          <h1 className="text-white text-3xl font-medium">
            Best Practices for Implementing MCT NMS
          </h1>
          <p>
            To maximize the benefits of MCT NMS, businesses should follow these
            best practices during the implementation process:
          </p>
          <li>
            Needs Assessment : Conduct a comprehensive assessment of the
            organization's network management requirements and workflows to
            ensure that MCT NMS aligns with specific needs.
          </li>
          <li>
            Thorough User Training : Provide comprehensive training to all
            users, including administrators and employees, to promote seamless
            adoption and optimal utilization of MCT NMS.
          </li>
          <li>
            Data Migration Strategy : Develop a structured data migration plan
            to transfer existing network data and configurations to MCT NMS
            accurately and efficiently.
          </li>
          <li>
            Security Implementation : Implement robust security measures to
            protect sensitive network data, including data encryption, user
            access controls, and regular security audits.
          </li>
          <li>
            Continuous Support and Updates : Ensure ongoing support and regular
            updates from MapleCloud Technologies to keep MCT NMS up-to-date and
            functioning optimally.
          </li>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
              Key features of NMS
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Network Monitoring
              </h1>
              <br />
              <li className="text-white">
                MCTNMS continuously monitors the health and availability of
                network devices and services. It supports various protocols such
                as SNMP, ICMP, HTTP, and more.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Fault Management
              </h1>
              <br />
              <li className="text-white">
                The system detects network faults, generates meaningful alarms,
                and notifies the appropriate stakeholders, allowing for quick
                identification and resolution of issues.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Performance Measurement
              </h1>
              <br />
              <li className="text-white">
                MCTNMS collects performance data, including response times,
                bandwidth utilization, and CPU/memory usage, to analyze trends
                and optimize resource allocation.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Provisioning and Configuration Management
              </h1>
              <br />
              <li className="text-white">
                It automates the provisioning of new devices and simplifies the
                management of device configurations centrally. Users have the
                option of manual/automated addition of devices/nodes into MCTNMS
                for monitoring.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Notification and Escalation
              </h1>
              <br />
              <li className="text-white">
                Administrators can set up flexible notification policies,
                ensuring that the right personnel are alerted promptly in the
                event of critical incidents.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
              Key Benefits of NMS
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Network Monitoring</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Automated Discovery</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
                Heightened Security and Compliance
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Alarms</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Events</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
                In app & Push Notifications
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Time Series Graph</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Thresholds</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Reporting Interface</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Network Topology</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nms;
