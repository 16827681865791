import React, { useState } from "react";
import img from "../../images/blog/Tech_blog.png";
import cyber from "../../images/blog/cyber.png";
import Aibased from "../../images/blog/Aibased.png";
import cloud2 from "../../images/blog/cloud.png";
import network from "../../images/blog/network.png";
import cloud from "../../images/blog/blucloud.png";
import women from "../../images/blog/women.png";
import banner from "../../images/products/product_banner.jpg";
import blog1 from "../../pages/data/formate1/blog1.json";

function Techblog() {
  const [data] = useState(blog1[0]);
  return (
    <div className="blog w-full ">
      <div className="w-full h-screen ">
        <img src={banner} alt="img" className="w-full h-[95%]" />
        <div className=" w-full absolute flex flex-col my-5 gap-4 px-10 bottom-10 ">
          <span className="text-yellow-400 text-lg font-medium">
            {data.category}
          </span>
          <p className="text-white text-3xl font-semibold pb-8 border-white">
            {data.title}
          </p>
        </div>
      </div>
      <div className="flex max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <p>{data.description}</p>
          <img src={img} alt="img" className="w-full h-64" />
          {data.topics.map((topic) => (
            <>
              <h1 className="text-white text-3xl font-medium">
                {topic.heading}
              </h1>
              <div className="w-full">
                <p>{topic.description}</p>
                {topic.points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </div>
            </>
          ))}

          <h1 className="text-white text-3xl font-medium">
            {data.main[0].heading}
          </h1>
          <div>
            <p>{data.main[0].description}</p>
            <br />
            {data.main[0].number.map((num, index) => (
              <div key={index}>
                <p>{num.title}</p>
                <p>{num.description}</p>
                {num.pointes.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
                <br />
              </div>
            ))}
            <h1 className="text-white text-2xl font-medium">Conclusion</h1>
            <br />
            <p>{data.conclusion}</p>
          </div>
        </div>
        <div className="w-2/5 max-[900px]:w-full p-4  ">
          <div className="w-full grid grid-cols-2 max-[400px]:pb-0 max-[400px]:grid-cols-2 max-[900px]:grid-cols-4 gap-3 max-[900px]:pb-24">
            <img src={cloud2} alt="img" className="w-full h-full" />
            <img src={network} alt="img" className="w-full h-full" />
            <img
              src={cyber}
              alt="img"
              className="w-full h-3/5 max-[900px]:h-full max-[400px]:h-3/5"
            />
            <img
              src={Aibased}
              alt="img"
              className="w-full h-3/5 max-[900px]:h-full max-[400px]:h-3/5"
            />
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col items-center gap-6 rounded-xl">
            <p className="text-white font-medium text-xl text-center">
              Read More
            </p>
            <div className="w-full grid grid-cols-1 max-[550px]:gap-2 gap-5 max-[900px]:grid-cols-2">
              <div className="w-full rounded-xl bg-opacity-45 bg-stone-600 shadow-xl">
                <img src={cloud} alt="img" className="w-full rounded-xl" />
                <div className="p-4 text-sm text-white max-[900px]:p-2 max-[550px]:text-xs">
                  <p>Cloud Security 101: Protecting Your Data in the Cloud </p>
                </div>
              </div>
              <div className="w-full h-full rounded-xl bg-opacity-45 bg-stone-600 shadow-xl">
                <img src={women} alt="img" className="w-full rounded-xl" />
                <div className="p-4 text-sm text-white max-[900px]:p-2  max-[550px]:text-xs">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing
                    elitLaboriosam .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Techblog;
