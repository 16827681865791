import { createContext, useContext } from "react";

 
  export const DataContext = createContext({
    blog_data : [],
    prod_data : [],
    blog : null,
    Each_proddetail : () => {},
    prod : null,
    setBlog : () => {},
  })

  export const DataProvider = DataContext.Provider;

  export const useData = () => {
    return useContext(DataContext);
  }