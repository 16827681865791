 
 import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { RxCross2 } from "react-icons/rx";
import AOS from 'aos';
import 'aos/dist/aos.css';

 function ResBar({show,setShow}) {
    useEffect(() => {
        AOS.init({duration : 500});
      },[])
   return (
     show? <div data-aos='right' className='res-bar w-full fixed  p-4 h-full  z-10 flex flex-col ' style={{left:'0%'}}>
     <RxCross2 onClick={() => setShow(false)} className='text-white text-3xl absolute right-6'/>
     <Link onClick={() => setShow(false)} to='/' className='px-5 py-3 text-white'>Home</Link>
     <Link onClick={() => setShow(false)}to='/productpage' className='px-5 py-3 text-white'>Products</Link>
     <Link  onClick={() => setShow(false)} to='/about' className='px-5 py-3 text-white'>About Us</Link>
     <Link onClick={() => setShow(false)} to='/services' className='px-5 py-3 text-white'>Service</Link>
     <Link onClick={() => setShow(false)} to='/contactpage' className='px-5 py-3 text-white'>Contact Us</Link>
     {/* <Link onClick={() => setShow(false)} to='/blog' className='px-5 py-3 text-white'>Blog</Link> */}
  </div>: <div data-aos='right' className='res-bar w-full fixed  p-4 h-full  z-10 flex flex-col ' style={{left:'-100%'}}>
     <RxCross2 onClick={() => setShow(false)} className='text-white text-3xl absolute right-6'/>
     <Link className='px-5 py-3 text-white'>Home</Link>
     <Link className='px-5 py-3 text-white'>Products</Link>
     <Link className='px-5 py-3 text-white'>About Us</Link>
     <Link className='px-5 py-3 text-white'>Service</Link>
     <Link className='px-5 py-3 text-white'>Contact Us</Link>
  </div>
   )
 }
 
 export default ResBar
 