import React, { useEffect } from 'react'
import img from '../../images/banner-img.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { TypeAnimation } from 'react-type-animation';
import { PiShieldWarningLight } from "react-icons/pi";
import { PiGlobeLight } from "react-icons/pi";
import { MdOutlinePhonelinkLock } from "react-icons/md";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { HiOutlineGlobeAsiaAustralia } from "react-icons/hi2";


function Banner() {
  useEffect(() => {
    AOS.init({duration : 1000});
  },[])
  return (
    <div className='banner w-full flex flex-col pb-10 items-center justify-center  '>
      <div className='w-full pt-20 px-20  max-[650px]:px-6 flex  justify-between  items-center'>
      <div className='content text-5xl max-[950px]:text-4xl max-[780px]:text-3xl max-[650px]:text-2xl font-bold text-white max-[500px]:pt-10 pb-5'>
        <span className='max-[500px]:text-5xl'>Welcome to Maple <br />Cloud Technologies</span>
        <br />
        <TypeAnimation
      sequence={[
        'Your Trusted Security Partner',
        1000,
        'Your Trusted Security Partner',
        1000,
        'Your Trusted Security Partner',
        1000,
        'Your Trusted Security Partner',
        1000
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '20px', display: 'inline-block' }}
      repeat={Infinity}
    />
        <br/>
       
      </div> 
      <img src={img} className=' w-[420px] max-[950px]:w-[300px] max-[780px]:w-[200px] max-[650px]:w-[200px] max-[500px]:hidden' alt="" />
      </div>
      <div className='max-[1020px]:grid-cols-3 max-[500px]:grid-cols-2 grid grid-cols-5 gap-6 w-full px-14 max-[650px]:px-6 max-[780px]:mt-5'>
        <div data-aos='zoom-in' className='w-full border border-white rounded-xl p-4  flex flex-col gap-3 items-center justify-center max-[500px]:p-2'>
          <PiShieldWarningLight className='text-5xl text-red-600 max-[500px]:text-4xl' />
          <p className='text-lg text-white font-medium text-center max-[500px]:text-sm '>EventShield (SIEM + XDR + SOAR)
          </p>
        </div>
        <div data-aos='zoom-in' className='w-full border border-white rounded-xl p-4  flex flex-col gap-3 items-center justify-center max-[500px]:p-2'>
          <PiGlobeLight className='text-5xl text-red-600 max-[500px]:text-4xl'/>
          <p className='text-lg text-white font-medium text-center max-[500px]:text-sm  '>Network Monitoring System (MCTNMS)

          </p>
        </div>
        <div data-aos='zoom-in' className='w-full border border-white rounded-xl p-4 flex flex-col gap-3 items-center justify-center  max-[500px]:p-2'>
          <MdOutlinePhonelinkLock className='text-5xl text-red-600 max-[500px]:text-4xl'/>
          <p className='text-lg text-white font-medium text-center max-[500px]:text-sm   '>Identity & Access Management (MCT Authenticator)

          </p>
        </div>
        <div data-aos='zoom-in' className='w-full border border-white rounded-xl p-4 flex flex-col gap-3 items-center justify-center max-[500px]:p-2'>
          <MdOutlineMarkEmailRead className='text-5xl text-red-600 max-[500px]:text-4xl'/>
          <p className='text-lg text-white font-medium text-center max-[500px]:text-sm  '>MailBridges

          </p>
        </div>
        <div data-aos='zoom-in' className='w-full border border-white rounded-xl p-3 flex flex-col gap-3 items-center justify-center max-[500px]:p-3'>
          <HiOutlineGlobeAsiaAustralia className='text-5xl text-red-600 max-[500px]:text-4xl'/>
          <p className='text-lg text-white font-medium text-center max-[500px]:text-sm  '>MapleDoc (DMS)

          </p>
        </div>
       
      </div>
    </div>
  )
}

export default Banner;
