import React from "react";
import { useData } from "../../blogContext/Context";
import maple_doc from "../../images/products/maple_doc.png";


function ProdDetail() {
  const { prod } = useData();
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-full max-[900px]:w-full flex flex-col gap-5 text-white text-md p-8 text-md">
          <img
            src={prod.img || maple_doc}
            alt="img"
            className="w-4/5 h-[380px] rounded-xl"
          />
          <div className="w-full flex items-center gap-2">
            <h1 className="text-white text-2xl font-medium">
              {prod.prod_name} :
            </h1>
            <p>{prod.prod_title}</p>
          </div>
          <p>{prod.description}</p>
          <h1 className="text-white text-2xl font-medium">{prod.question}</h1>
          <p>{prod.answer}</p>
       {
        prod.about.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
          <h1 className="text-white text-2xl font-medium">{data.title}</h1>

          {
            data.points.map((point,index) => (
              <li key={index}>
           <span className="font-medium text-white"> {point.title}</span> {point.content}
          </li>
            ))
          }
        </div>
        ))
       }
        </div>
      </div>
    </div>
  );
}

export default ProdDetail;
