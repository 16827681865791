 
  import React from 'react'
import About_banner from './About_banner'
import AboutUs from './AboutUs'
import Jouney from './Jouney'

  
  function AboutPage() {
    return (
      <div>
        <About_banner/>
        <AboutUs/>
        <Jouney/>
      </div>
    )
  }
  
  export default AboutPage
  