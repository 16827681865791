import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import ScrollBtn from "./components/ScrollBtn";
import { DataProvider } from "./blogContext/Context";
import data from "../src/pages/Products/Data.json";
import axios from "axios";
function Layout({ children }) {
  const [blog_data, setBlog_Data] = useState([]);
  const [blog, setblog] = useState(null);
  const [prod_data, setProd_data] = useState([]);
  const [prod, setProd] = useState(null);

  const setBlog = (id) => {
    blog_data.filter((obj) => (obj.id === id ? setblog(obj) : ""));
  };

  const setProd_detail = (id) => {
    prod_data.filter((obj) => (obj.id === id ? setProd(obj) : ""));
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [btn, setBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setBtn(true);
      } else {
        setBtn(false);
      }
    });
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "http://192.168.100.191:8081/api/maple/blog"
        );
        if (res.status == 200) {
          setBlog_Data(res.data);
        } else {
          console.log("error accured");
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchProd_data = () => {
      if (data) {
        const res = data;
        setProd_data(res);
      } else {
        return;
      }
    };
    fetchProd_data();
  });
  return (
    <DataProvider
      value={{ blog_data, blog, setBlog, prod_data, setProd_detail, prod }}
    >
      <Navbar />
      {children}
      {btn ? <ScrollBtn /> : ""}
      <Footer />
    </DataProvider>
  );
}

export default Layout;
