import React from "react";
import img1 from "../../images/products/blog-two-1.jpg";
import img2 from "../../images/products/blog-two-2.jpg";
import img3 from "../../images/products/blog-two-3.jpg";
import img4 from "../../images/products/blog-two-4.jpg";
import img7 from "../../images/products/ncms.jpg";
import img8 from "../../images/products/switch.jpg";
import img9 from "../../images/products/product_SDWN.png";
import img12 from "../../images/products/email-gatway.jpg";
import firewall from "../../images/products/firewall.jpg";
import maple_doc from "../../images/products/maple_doc.png";
import banner from "../../images/products/products.png";
import nac from "../../images/products/download.jpg";
import log from "../../images/products/blog-two-5.jpg";
import {  useNavigate } from "react-router-dom";
import { useData } from "../../blogContext/Context";

function ProductPage() {
  const navigate = useNavigate();
  const {prod_data,setProd_detail} = useData();
  return (
    <div className="product-page w-full ">
       <div className="w-full h-screen max-[500px]:h-[50vh] bg-black bg-opacity-25">
        <img src={banner} alt="img" className="w-full h-[100%]  " />
        <div className=" w-full absolute flex flex-col my-5 gap-4 px-10 bottom-[10%] max-[500px]:bottom-[55%]">
       <span className="text-white text-6xl font-bold max-[500px]:text-3xl " >Our Products</span>
       <span className="w-40 h-2 rounded-full bg-white max-[500px]:h-1" ></span>
     </div>
      </div>
      <div className="w-full grid grid-cols-3 max-[650px]:grid-cols-1 max-[1200px]:grid-cols-2 py-32 px-10 gap-6">
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/siem")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">EventShield</span>
              <span className="text-sm font-medium"> (SIEM+XDR+SOAR)</span>
            </p>
            <img src={img1} alt="img" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/mct_auth")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">MCT Authenticator</span>
            </p>
            <img src={img3} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/mapledoc")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">MapleDoc</span>
              <span className="text-sm font-medium">
                {" "}
                (Document Management System)
              </span>
            </p>
            <img src={img2} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/nms")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">MCTNMS</span>
              <span className="text-sm font-medium">
                {" "}
                (Network Security Management)
              </span>
            </p>
            <img src={img4} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/mailbrige")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">MailBridges</span>
              <span className="text-sm font-medium">
                (Email Gateway Solutions)
              </span>
            </p>
            <img src={img12} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/ncms")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">NCMS</span>
            </p>
            <img src={img7} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
          <a href='https://www.devray.in' target="_blank" >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">DevRay Switches</span>
            </p>
            <img src={img8} alt="" className="rounded-3xl" />
          </a>
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div onClick={()=> navigate('/sdwan')} className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">AgentNet (SDWAN)</span>
            </p>
            <img src={img9} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
          <a href='https://www.devray.in' target="_blank" >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">Next Gen-FIREWALL</span>
            </p>
            <img src={firewall} alt="" className="rounded-3xl" />
            </a>
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div onClick={() => navigate('/nac')} className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">MCTNAC</span>
            </p>
            <img src={nac} alt="" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div onClick={() => navigate('/log')}  className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">MCTLogs</span>
            </p>
            <img src={log} alt="" className="rounded-3xl" />
          </div>
        </div>
        {
          prod_data.map((data) => (
            <div key={data.id} className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div onClick={() => {
            navigate('/prod_detail')
            setProd_detail(data.id)
          }}  className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">{data.prod_name}</span>
            </p>
            <img className="rounded-2xl h-full w-full" src={data.img || maple_doc} />
          </div>
        </div>
          ))
        }
      </div>
    </div>
  );
}

export default ProductPage;
