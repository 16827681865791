import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import aims from "../../images/service/aiims.png";
import img1 from "../../images/service/CBIS.png";
import img2 from "../../images/service/partner-1.png";
import img3 from "../../images/service/partner-2.png";
import img4 from "../../images/service/partner-3.png";
import img5 from "../../images/service/partner-4.png";
import img6 from "../../images/service/partner-5.png";
import img7 from "../../images/service/partner-6.png";
import img8 from "../../images/service/partner-7.png";
import img9 from "../../images/service/partner-8.png";
import img10 from "../../images/service/partner-9.png";
import img11 from "../../images/service/SBI CARD.png";
import img13 from "../../images/service/IIT.png";
import img12 from "../../images/service/Embassy of India.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Goals() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  let box = document.getElementById("img-container");

  const handleNext = () => {
    let width = box?.clientWidth;
    box.scrollLeft = box?.scrollLeft + width;
  };
  const handlePrev = () => {
    let width = box?.clientWidth;
    box.scrollLeft = box?.scrollLeft - width;
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home-goal w-full relative px-20 max-[800px]:px-5  max-[730px]:px-6 py-20 flex flex-col gap-20 items-center">
      <div className="w-3/5 max-[730px]:w-full flex items-center gap-3 flex-col">
        <h1 className="text-white text-4xl text-center font-bold ">
          OUR HAPPY CLIENTS
        </h1>
      </div>

      <div className="w-full h-full m-0 p-0 mt-5 flex flex-col justify-center items-center ">
        <div className="w-[90%] h-5/6  max-sm:rounded-md  ">
          <Slider {...settings}>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={aims} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img1} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img2} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img3} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img4} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img5} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img6} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img7} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img8} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img9} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img10} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img11} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img12} alt="img" className="w-full rounded-xl" />
            </div>
            <div data-aos="zoom-in" className="flex-none  px-2">
              <img src={img13} alt="img" className="w-full rounded-xl" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Goals;
