import React from "react";
import rim from "../../images/products/blog-two-6.jpg";
import { IoPricetagsOutline } from "react-icons/io5";

function Rime() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={rim} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
          Maple Cloud Technologies is a Cert-In empanelled information security company, ranked among the top 50 Information Security Companies in India. With a presence in Delhi NCR and Mumbai, we help our customers meet their business requirements. Our team has over two decades of experience in providing consulting services to meet customer needs. MCT is a dynamic Information Security company catering to more than 100 businesses across various sectors. When everything is connected, security plays a crucial role in running a business smoothly. That's why MCT delivers solutions that protect every aspect of cybersecurity for businesses of all sizes: Small, Medium, and Large. We provide services to protect critical information, systems, and operations, helping to create a safer environment.
          </p>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
            Key feature of RIM
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Authentication
              </h1>
              <br />
              <li className="text-white">
              MCTAuthenticator provides centralized authentication for network access. It supports a wide range of authentication methods, including EAP-TLS, PEAP, MS-CHAP, and PAP.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Accounting:
              </h1>
              <br />
              <li className="text-white">
              MCTAuthenticator provides centralized accounting for network access. It logs the network activity, including session start and stop times, data usage, and connection quality.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Extensibility
              </h1>
              <br />
              <li className="text-white">
              MCTAuthenticator is highly extensible and supports a wide range of third-party modules, including LDAP, SQL, and Kerberos to work as a backend.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              High Performance
              </h1>
              <br />
              <li className="text-white">
              MCTAuthenticator is designed for high performance and can handle large numbers of authentication and accounting requests. Scalability: MCTAuthenticator is scalable and can be deployed in distributed environments to handle large numbers of authentication and accounting requests.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
            Key Benefits of MCT RIM
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
              Centralized log management
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Audit trai</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Ability to identify data</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">breaches</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">GDPR (72h notification)</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Dedicated experts</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
              Hardware, software, and servic
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rime;
