import React from "react";
import nms from "../../images/home_prod_img/NMS.png";
import auth from "../../images/home_prod_img/Auth.png";
import siem from "../../images/home_prod_img/SIM.png";
import sdwan from "../../images/home_prod_img/SDwan.png";
import HRMS from "../../images/hrms.png";

function TopProd() {
  return (
    <div className="home-testimonial min-[900px]:h-[120vh] relative w-full px-20 max-[940px]:px-5  max-[730px]:px-6  py-10 flex flex-col gap-20 items-center">
      <div className="w-3/5 max-[730px]:w-full flex items-center gap-3 flex-col">
        <h1 className="text-white font-medium text-5xl text-center max-[730px]:text-3xl">
          Start Using Cyber Security
          <br />
          Protect
        </h1>
      </div>
      <div className="w-full max-[940px]:flex max-[940px]:flex-col">
        <div className="ribbon w-4/5 rounded-full relative max-[940px]:ml-0 max-[940px]:rounded-2xl max-[940px]:w-full -ml-40 -mt-10 flex flex-col  gap-6 bg-neutral-700 bg-opacity-45 py-12 max-[500px]:py-7 px-24 max-[500px]:px-4  max-[940px]:px-10">
          <div className="w-full flex justify-between items-center text-white max-[500px]:text-xl text-4xl font-medium gap-5  max-[940px]:gap-2">
            <span>99%</span>
            <span>10 </span>
            <span>100+</span>
            <span>500+</span>
          </div>
          <div className="w-full flex justify-between items-center text-cyan-400 max-[500px]:text-[10px] text-md font-medium gap-5 max-[940px]:gap-2">
            <span>Client Retention</span>
            <span>Years Of Service</span>
            <span>Team Of Professionals</span>
            <span>Satisfied Clients</span>
          </div>
        </div>

        <div className="grid grid-cols-4 max-[500px]:gap-4  max-[500px]:grid-cols-2 gap-10 max-[940px]:mt-10">
          <div className="flex flex-col gap-3 items-center min-[940px]:absolute right-28 top-32">
            <img src={siem} alt="simg-img" className="w-52 h-32" />
            <span className="text-md text-cyan-500 font-medium">SIEM</span>
          </div>
          <div className="flex flex-col gap-3 items-center min-[940px]:absolute right-52 top-[43%] max-[1060px]:right-32">
            <img src={auth} alt="simg-img" className="w-52 h-32" />
            <span className="text-md text-cyan-500 font-medium text-center">
              MCT AUTHENTICATOR
            </span>
          </div>
          <div className="flex flex-col gap-3 items-center min-[940px]:absolute right-[30%] top-[67%]">
            <img src={nms} alt="simg-img" className="w-52 h-32" />
            <span className="text-md text-cyan-500 font-medium">NMS</span>
          </div>
          <div className="flex flex-col gap-3 items-center min-[940px]:absolute right-[80%] top-[67%]">
            <img src={sdwan} alt="simg-img" className="w-52 h-32" />
            <span className="text-md text-cyan-500 font-medium">SDWAN</span>
          </div>
          <div className="flex flex-col gap-3 items-center min-[940px]:absolute right-[55%] top-[67%]">
            <img src={HRMS} alt="simg-img" className="w-52 h-32 rounded-3xl" />
            <span className="text-md text-cyan-500 font-medium">HRMS</span>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default TopProd;
