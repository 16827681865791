import React from "react";
import siem from "../../images/products/SIEM.png";
import img1 from "../../images/products/docker.png";
import img2 from "../../images/products/vul_dashboard.png";
import { IoPricetagsOutline } from "react-icons/io5";

function Ict() {
  return (
    <div className="blog w-full ">
      {" "}
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={siem} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            n an era where technology constantly evolves, the threat landscape
            grows increasingly complex and dynamic. Disruptive technologies such
            as social media, cloud computing, big data, and mobile devices
            challenge existing security measures and can jeopardize an
            organization's data and intellectual property. <br />
            <br />
            At MCT, we specialize in crafting secure strategies to transform
            your ICT security. We focus on delivering comprehensive risk
            management solutions and fostering awareness through in-depth
            strategies, monitoring, evaluation, and reporting. Our goal is to
            enhance the flexibility and cost-effectiveness of your ICT security
            and compliance programs, ensuring robust protection against emerging
            threats.
          </p>
          <h1 className="text-white text-3xl font-medium">Our Approach</h1>
          <p>
            MCT’s approach to ICT security includes the following key aspects:
          </p>
          <li>
            Data Protection: Safeguard sensitive and confidential data from
            unauthorized access, theft, and manipulation. By implementing
            encryption, access controls, and data loss prevention (DLP)
            solutions, we protect your data assets from cyber threats, ensuring
            data integrity and confidentiality.
          </li>
          <li>
            Data Protection: Safeguard sensitive and confidential data from
            unauthorized access, theft, and manipulation. By implementing
            encryption, access controls, and data loss prevention (DLP)
            solutions, we protect your data assets from cyber threats, ensuring
            data integrity and confidentiality.
          </li>
          <li>
            Risk Mitigation: Identify, assess, and mitigate risks related to
            cybersecurity threats, vulnerabilities, and compliance requirements.
            Through risk assessments, security controls, and continuous
            monitoring, we help reduce the likelihood and impact of cyber
            attacks and data breaches.
          </li>
          <h1 className="text-white text-3xl font-medium">Features</h1>
          <p>
            Our comprehensive ICT security measures are designed to support
            business continuity and resilience
          </p>
          <li>
            Business Continuity and Resilience: Prevent disruptions to critical
            systems and services. By implementing backup and recovery solutions,
            disaster recovery plans, and redundant infrastructure, we ensure
            your organization can minimize downtime, recover quickly from cyber
            incidents, and maintain operational continuity.
          </li>
          <h1 className="text-white text-3xl font-medium">Get Started</h1>
          <p>
            Let MCT build a secure strategy to transform your ICT security.
            Contact us today to learn how we can help you stay ahead of threats
            and ensure the safety and integrity of your organization's data and
            systems
          </p>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">MCT ICT Security</p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Regulatory Compliance
              </h1>
              <br />
              <li className="text-white">
                ERP solutions streamline financial management processes,
                including accounting, budgeting, invoicing, and financial
                reporting. By integrating financial data from various sources,
                ERP systems enable organizations to track expenses, manage cash
                flow, comply with regulatory requirements, and optimize
                financial performance.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Protection of Intellectual Property
              </h1>
              <br />
              <li className="text-white">
                CT security measures protect intellectual property, trade
                secrets, and proprietary information from theft, espionage, and
                unauthorized disclosure.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Preservation of Reputation and Trust
              </h1>
              <br />
              <li className="text-white">
                ICT security measures help organizations preserve their
                reputation and build trust with customers, partners, and
                stakeholders. By protecting customer data, preventing data
                breaches.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Cost Savings
              </h1>
              <br />
              <li className="text-white">
                ICT security measures can result in cost savings by preventing
                financial losses associated with cyber attacks, data breaches,
                and regulatory non-compliance.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
              Key Benifits of MCT ERP Solutions
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Security Enhancement</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Compliance Adherence</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Operational Efficiency</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Cost Savings</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">User Productivity</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Single Sign-On (SSO)</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Reduced IT Workload</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Risk Mitigation</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Password Managemen</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Scalability</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ict;
