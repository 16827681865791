 import React from 'react'
 import { LuChevronUp } from "react-icons/lu";

 function ScrollBtn() {
    const backTotop = () => {
        window.scrollTo({
          top : 0,
          behavior : 'smooth'
        })
      }
   return (
       <LuChevronUp  onClick={backTotop}   className='fixed right-10 bottom-10 shadow-lg p-1 text-white bg-cyan-600 rounded-full w-12 h-12 cursor-pointer'/>
   )
 }
 
 export default ScrollBtn
 