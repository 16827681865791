import React from "react";
import { CiLinkedin } from "react-icons/ci";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { FaChevronRight } from "react-icons/fa6";

function Footer() {
  return (
    <div className="footer  w-full gap-4 flex flex-col max-[1250px]:px-5 items-center py-10 px-20 jusitfy-center">
      <div className="w-full gap-10 grid grid-cols-3  max-[1250px]:grid-cols-2 max-[700px]:grid-cols-1 max text-white">
        <div className="flex flex-col w-full  text-white ">
          <img src={logo} alt="logo img" className="w-[180px]" />
          <div className="flex mt-5 gap-5 text-3xl font-medium text-white items-center">
            <a
              href="https://www.youtube.com/@MapleCloudTechnologies"
              target="blank"
            >
              <PiYoutubeLogoLight />
            </a>
            <a
              href="https://in.linkedin.com/company/maplecloudtechnologies?trk=similar-pages"
              target="blank"
            >
              <CiLinkedin />
            </a>
          </div>
        </div>

        <div className="flex text-white flex-col w-full gap-3 mt-3">
          <h1 className="font-medium w-full text-lg ">Service Links</h1>

          <div className="flex flex-col gap-2  text-sm ">
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/cloud"> Cloud</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/rim">MCTRIM</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/data_center">Data Center</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/network">Enterprise Network</Link>
            </div>

            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/manageservice">Managed Services</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/operation">Network Field Operation</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/ict">ICT Security</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/mtech">Multi-vendor Technical Support</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/nrc">Network Rollout Commissioning</Link>
            </div>
          </div>
        </div>
        <div className="flex text-white flex-col w-full gap-3">
          <h1 className="font-medium w-full text-lg ">Product Links</h1>

          <div className="flex flex-col gap-2  text-sm ">
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/siem">EventShield (SIEM)</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/mct_auth">MCT Authenticator</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/mapledoc">MapleDoc</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/nms">MCT-NMS</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>HRMS</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>DAST</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>LMS</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/mailbrige">MailBridges</Link>
            </div>
            {/* <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to='/ncms'>MCT-NCMS</Link>
            </div> */}
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/sdwan">AgentNet (SDWAN)</Link>
            </div>
            {/* <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>AccessWatch (NAC) </Link>
            </div> */}
            {/* <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>MCT-Firewall</Link>
            </div> */}
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>MapleLogs</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <a href="https://www.devray.in">Devray Switches & Firewall</a>
            </div>
          </div>
        </div>
        {/* <div className="flex text-white  flex-col w-full gap-3">
          <h1 className="font-medium w-full text-lg ">Quick Links</h1>
          <div className="text-sm flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>Terms and Services</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>Privacy Policy</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>Authentication</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link>Partner Registration</Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
