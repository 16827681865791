 
 import React from "react";
 import ncms from "../../images/products/mct-ncms.jpg";
 
 function Nac() {
   return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
        <img src={ncms} alt="img" className="w-full h-[400px] rounded-xl" />
        <h1 className="text-white text-3xl font-medium">Introduction</h1>
        <p>
        MCT offers a comprehensive pre- and post-connect Network Access Control (NAC) solution for wired and wireless LAN and VPN customers. Our solution is standards-based and compatible with multiple vendors, allowing IT managers to ensure that only authorized users access the appropriate information from the right location at the right time. Utilizing MCT Identity & Access appliances or virtual appliances with our NAC management, configuration, and reporting software, we provide robust access control.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Fine-Grained Configuration Options
        </h1>
       <p>AccessWatch (NAC) offers unmatched flexibility for detailed network control. Configuration options include time, location, authentication methods, device and OS type, and user groups. Policies can be tailored to permit specific levels of network access based on these criteria, enhancing security and efficiency, especially in BYOD environments.</p>
        <h1 className="text-white text-3xl font-medium">
        Guest Account Services
        </h1>
       <p>Secure guest networking is simplified with AccessWatch (NAC)'s automatic guest registration and access control features, reducing the burden on IT staff. Sponsors can verify visitor registration through email sponsorship, and guests can self-register multiple devices. LDAP connectivity allows dynamic role assignment, and location-based registration restricts guest access as needed.</p>
        <h1 className="text-white text-3xl font-medium">
        Reporting & Notifications
        </h1>
       <p>AccessWatch (NAC)'s sophisticated notification engine integrates with existing alerting solutions, providing extensive capabilities to further reduce operating costs. Notifications can be sent via email, web services, syslog, or traps, and the notification engine can execute programs based on events. End-system reporting is simplified with web-based data displays and customizable dashboards, enhancing visibility and troubleshooting capabilities.</p>
        <h1 className="text-white text-3xl font-medium">
        Integrations
        </h1>
       <p>AccessWatch (NAC) facilitates seamless integration with Software Defined Networking (SDN), NetSight monitoring, and various NAC solutions, including MDM connectors with vendors like Airwatch, MobileIron, and JAMF Software. This comprehensive SDN solution enhances data center management and network security.</p>
        <h1 className="text-white text-3xl font-medium">
        Identity-Aware Networking
        </h1>
       <p>AccessWatch (NAC) restricts user capabilities based on their identity and assigned access policies, integrating with identity sources like Microsoft Active Directory. Automated user lifecycle management, including enrollment and termination, strengthens network security and reduces operating costs.</p>
        <h1 className="text-white text-3xl font-medium">
        Baselining and Monitoring Endpoints
        </h1>
       <p>Our NAC system integrates with all end systems in the network architecture, offering agent-based or agent-less endpoint assessment capabilities. This flexibility allows for thorough security posture examinations and automatic remediation, ensuring comprehensive network protection.</p>
       <h1 className="text-white text-3xl font-medium">
        Get Started
        </h1>
       <p>Enhance your network security and control with MCT's advanced NAC solutions. Contact us today to learn how we can help you implement a robust and flexible network access control system tailored to your needs.</p>
      </div>
   
    

      <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
          <p className="text-white font-medium text-xl ">
          Key feature of AccessWatch (NAC)
          </p>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Business-Oriented Visibility and Control
            </h1>
            <br />
            <li className="text-white">
            AccessWatch (NAC) provides visibility and control over specific users and applications in multi-vendor infrastructures. Our NAC solution safeguards your existing infrastructure investments without requiring new switching gear or end system agents.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Vulnerability Assessment and Remediation
            </h1>
            <br />
            <li className="text-white">
            Conduct thorough vulnerability assessments with supported remediation and multi-user, multi-method authentication. Control access for authorized internal users and visitors, ensuring security while maintaining efficiency.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Flexible Policy Management
            </h1>
            <br />
            <li className="text-white">
            Implement policies based on device type, time, location, user identification, and other environmental factors. Allow, reject, prioritize, rate-limit, tag, reroute, and audit network traffic with fine-grained control.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            RADIUS Compatibility
            </h1>
            <br />
            <li className="text-white">
            AccessWatch (NAC) supports any device using RADIUS for authorization, allowing customizable policies based on the RADIUS reject property. This enables unparalleled interoperability with Layer 2, Layer 3, and VPN access protocols.
            </li>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Staggered Deployment Options
            </h1>
            <br />
            <li className="text-white">
            AccessWatch (NAC)'s flexibility allows for phased deployment, starting with basic endpoint detection and gradually adding authentication, authorization, and automated remediation.
            </li>
          </div>
        </div>
      </div>
      </div>
    </div>
   )
 }
 
 export default Nac
 