import React from "react";
import ncms from "../../images/products/mct-ncms.jpg";
import { IoPricetagsOutline } from "react-icons/io5";

function Ncms() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={ncms} alt="img" className="w-full h-[400px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            Looking for a comprehensive Network Configuration Management (NCM)
            solution that seamlessly integrates with all types of devices? Look
            no further! Our cutting-edge solution is specifically designed to
            cater to the diverse network infrastructure needs of businesses,
            regardless of the devices they use. With our reliable and
            user-friendly software, you can easily manage and configure your
            network devices, ensuring optimal performance and effortless
            connectivity. <br />
            <br />
            In the fast-paced world of modern business, a robust and adaptable
            network infrastructure is crucial for seamless operations. As
            businesses evolve, so do the complexities of managing diverse
            network devices. Our NCM solution is tailored to meet these diverse
            network infrastructure needs, providing a reliable and user-friendly
            platform for effortless connectivity and optimal performance.
          </p>
          <h1 className="text-white text-3xl font-medium">
            Key features and Benefits
          </h1>
          <li>
            Universal Compatibility:Our solution is built to seamlessly support
            a wide range of devices, including routers, switches, firewalls, and
            more. It can handle mixed vendor environments and devices from
            different manufacturers with ease.
          </li>
          <li>
            Centralized Configuration Management: Say goodbye to the hassle of
            managing configurations separately for each device. Our solution
            provides a centralized platform where you can easily deploy, modify,
            and update configurations across your entire network, regardless of
            the device type.
          </li>
          <li>
            Automated Configuration Backups: Never lose critical configuration
            settings again. With our solution, you can automate configuration
            backups for all your network devices, ensuring quick recovery and
            minimal downtime in the event of a failure or security breach.
          </li>
          <li>
            Version Control and Auditing: Stay in control of your network
            configurations with our built-in version control and auditing
            capabilities. Keep track of changes made to configurations, revert
            to previous versions if needed, and maintain a detailed audit trail
            for compliance and troubleshooting purposes.
          </li>
          <li>
            Compliance and Security: Our solution helps you ensure compliance
            with industry standards and regulations by providing configuration
            templates and guidelines. It also offers security features such as
            password management, device-level access controls, and real-time
            monitoring to safeguard your network against unauthorized access and
            potential threats.
          </li>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
              Key feature of MCT-NCMS
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Robust NCM Platform
              </h1>
              <br />
              <li className="text-white">
                Configuration changes and history Active and static config mgmt
                Fastest configuration Search Full multi vendor
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">Security</h1>
              <br />
              <li className="text-white">
                Full config compliance scanning Full config compliance reporting
                SSO, LDAP, and encryption Secure vault integrations
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                API Connectivity
              </h1>
              <br />
              <li className="text-white">
                Connect & backup any API Config backup for API endpoints Connect
                to any API services Use rConfig features on API data
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                High Performance
              </h1>
              <br />
              <li className="text-white">
                MCTAuthenticator is designed for high performance and can handle
                large numbers of authentication and accounting requests.
                Scalability: MCTAuthenticator is scalable and can be deployed in
                distributed environments to handle large numbers of
                authentication and accounting requests.
              </li>
            </div>
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
              Key Benefits of MCT-NCMS
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Configuration Backup</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Change Management</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Compliance Auditing</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
                Automated Configuration Templates
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">GDPR 72h notification</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Policy Enforcement</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ncms;
