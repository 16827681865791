import React from "react";
import mail from "../../images/products/mail.jpg";
import { IoPricetagsOutline } from "react-icons/io5";

function MailBridge() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={mail} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            MailBridges is an email server solution that provides a
            full-featured email server with an integrated webmail client. It
            supports multiple domains, users, and administrators and can be used
            with different email protocols such as SMTP, POP3, and IMAP. MCT
            Email Gateway manages emails using different mail transfer agents
            (MTAs) such as Postfix and Dovecot. This robust and secure email
            server solution offers a range of features for email management,
            with an easy-to-use interface and flexibility that allows
            integration with any third-party application for email management.{" "}
            <br />
            <br />
            MailBridges includes a complete email server stack, featuring email
            storage, email transfer, and email delivery components. Here’s a
            brief overview of how MCT Email Gateway works:
          </p>
          <h1 className="text-white text-3xl font-medium">
          Installation

          </h1>
         <p>MailBridges provides a script that automates the installation process, making it easy for even non-technical users to set up an email server.</p>
         
          <h1 className="text-white text-3xl font-medium">
          Configuration          </h1>
          <p>
          Once installed, MailBridges needs to be configured. This involves setting up email domains, users, and aliases. The web-based interface simplifies the configuration process, making it user-friendly.
          </p>
       
          <h1 className="text-white text-3xl font-medium">
          Email Storage
          </h1>
          <p>
          MailBridges uses a combination of Postfix, Dovecot, and MariaDB to store emails. Postfix handles the reception of emails, while Dovecot provides a secure and scalable IMAP/POP3 server. MariaDB stores email user accounts and other email-related data.
          </p>
          <h1 className="text-white text-3xl font-medium">
          How MailBridges Works
          </h1>
         <li>Email Transfer:When an email is sent to a user on an MailBridges server, it is first received by the Postfix mail transfer agent (MTA). Postfix checks the email address and relays the email to the appropriate email server.
         </li>
         <li>
Email Delivery: Once the email is received by the user's email server, Dovecot provides the email client with access to the email via IMAP or POP3 protocols.
</li>
<li>Anti-Spam and Anti-Virus: MailBridges includes built-in anti-spam and anti-virus features to protect the email server from spam and malware. SpamAssassin is used for spam filtering, while ClamAV is used for anti-virus scanning.
</li>
<li>
Webmail: MailBridges also includes a web-based email client called Roundcube. Roundcube provides users with a simple and intuitive interface for accessing their email from any device with an internet connection.</li>
   
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
            Key feature of MailBridges
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              SL/TLS encryption
              </h1>
              <br />
              <li className="text-white">
              MailBridges supports SSL/TLS encryption for secure communication between email clients and the mail server. This helps protect sensitive information such as passwords and email content from interception.

              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Spam and virus filtering
              </h1>
              <br />
             <li className="text-white"> MailBridges includes built-in spam and virus filtering to help protect against spam emails and email-borne viruses. The spam filter uses several techniques, such as IP reputation checks, SPF and DKIM validation, and machine learning algorithms to filter out unwanted email.</li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Password policies
              </h1>
              <br />
              <li className="text-white">
              MailBridges allows you to enforce strong password policies for user accounts, such as minimum password length, complexity requirements, and password expiration. This helps prevent unauthorized access to user accounts..
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
              Firewall and fail2ban              </h1>
              <br />
              <li className="text-white">
              MailBridges includes firewall and fail2ban, which can help protect your mail server from unauthorized access attempts. The firewall blocks traffic from suspicious IP addresses, while fail2ban blocks IP addresses that repeatedly fail login attempts.
              </li>
            </div>
            
          </div>
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
            <p className="text-white font-medium text-2xl ">
            Key Benefits of MailBridges
            </p>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Email Multi-Protocols Support</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Multi-Domain Support</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
              Multi-Language Support
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Security Features</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">User and Domain Management</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">
              Storage Options
              </p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Clustering and High Availability</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Integration with External Services</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Automated Updates</p>
            </div>
            <div className="flex items-center gap-4">
              <IoPricetagsOutline className="text-white text-xl" />
              <p className="text-white  text-md ">Mobile Device Compatibility</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MailBridge;
