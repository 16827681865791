 
    
 import React from "react";
 import img from "../../images/service/tex_support.jpg";
 import img1 from "../../images/products/docker.png";
 import img2 from "../../images/products/vul_dashboard.png";
 import { IoPricetagsOutline } from "react-icons/io5";
 
 function Mtech() {
   return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
        <img src={img} alt="img" className="w-full h-[380px] rounded-xl" />
        <h1 className="text-white text-3xl font-medium">Introduction</h1>
        <p>
        Trust MCT to be your comprehensive ICT maintenance and technical support provider. We offer a consolidated solution to streamline your maintenance and support services into one manageable contract with a single point of contact. Our customizable services simplify the complexity of managing multi-vendor maintenance and support, covering a broad portfolio that includes OEM platforms such as Cisco, Dell, Juniper, EMC, Hitachi, HP, IBM, NetApp, and Oracle.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Why Choose MCT?
        </h1>
        <p>
        With our enhanced analytics, global infrastructure, and years of experience, we maintain robust service level agreements that ensure a unified response to any technical challenges faced by our customers worldwide. We are committed to providing exceptional support tailored to your needs.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Our Services
        </h1>
        <p>
        Our service offerings include:
        </p>
        <li>7x24x365 Support: Round-the-clock support to ensure your operations run smoothly.
        </li>
        <li>Help Desk Services: Access expert assistance whenever you need it.
        </li>
        <li>Proactive Network Monitoring: Stay ahead of issues with continuous network monitoring.
        </li>
         <li>Automated Diagnostics and Ticket Opening: Streamline problem resolution with automated systems.</li>
         <li>Spare Management Efficient management of spare parts to minimize downtime.
         </li>
         <li>Reactive Systems: Comprehensive reactive support tailored to your requirements
         </li>
        <h1 className="text-white text-3xl font-medium">
        Customized Support Plans
        </h1>
        <p>
        At MCT, we tailor our support service plans to meet the unique needs of each customer. Whether you require continuous support, help desk services, proactive monitoring, or a reactive system, we have you covered. Our customer support center is always ready to assist, ensuring you receive the precise level of support your business demands.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Get Started
        </h1>
        <p>
        Simplify your ICT maintenance and technical support with MCT. Contact us today to learn how our multi-vendor support solutions can enhance your operational efficiency and reliability.
        </p>
      </div>

      <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
          <p className="text-white font-medium text-2xl ">
          Key Benefits of Multi-Vendor Technical Support
          </p>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Security Enhancement</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Compliance Adherence</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Operational Efficiency</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">
            Cost Savings</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">User Productivity</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Single Sign-On (SSO)</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">
            Reduced IT Workload</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">
            Risk Mitigation
            </p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Password Managemen</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Scalability</p>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
 }
 
 export default Mtech
 